import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import * as ServerApi from "../constants/ServerApiM";
import * as Interface from "../mobile-pages/Interface/Interface";
import "../css/DragItem.css";
import { RootState } from "../component/redux/rootReducer";
import styled from "styled-components";
import MobileHeader from "./Compornent/MobileHeader";
import AccountButton from "./Compornent/AccountButton";
import AlertModal from "react-modal";
import MusicContents from "./Compornent/MusicContents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faArrowLeft,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { IoCloseCircle, IoFolderOpenOutline } from "react-icons/io5";
import MusicFolder from "./Compornent/MusicFolder";
import AWS from "aws-sdk";
import Config from "../constants/Config";
import trash from "../img/Trash.svg";
import backbutton from "../img/arrow_back.svg";
import upload from "../img/Upload cloud.svg";
import edit from "../img/Settings.svg";
import rotate from "../img/Rotate ccw.svg";
import repeat from "../img/Repeat.svg";
import check from "../img/Check.svg";
import zoom from "../img/Search.svg";
import { FaRegFolder } from "react-icons/fa6";
import NewSaveButton from "./Compornent/NewSaveButton";
import LoadingProgress from "../component/LoadingProgress";

interface ProgramsVideoContainerProps {
  isModalOpen: boolean;
}

const ProgramsVideoContainer = styled.div<ProgramsVideoContainerProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  padding-top: 70px;

  .containerWrap {
    width: 100%;
    padding: 0 15px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }

  .hamburgerIcon {
    position: absolute;
    top: 10px;
    right: 5px;
  }

  .hamburgerWrap {
    position: absolute;
    top: 15px;
    right: 50px;
    background-color: #cccccc;
    border-radius: 15px;
    padding: 10px 15px;
    z-index: 999;
    text-align: left;
  }

  .hamburgerWrap li {
    margin: 15px 0;
    font-size: 1rem;
    font-weight: 500;
  }

  .hamburgerWrap li:hover {
    font-weight: 700;
  }
`;

const Overlay = styled.div<{ isModalOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 10, 0.85);
  display: ${({ isModalOpen }) => (isModalOpen ? "block" : "none")};
  z-index: 99;
`;

const MusicNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 10px 0 0;
  position: relative;
`;

interface NavIconWrapperProps {
  filter: string;
}

const NavIconWrapper = styled.div<NavIconWrapperProps>`
  display: inline-flex;
  filter: ${({ filter }) =>
    filter ||
    "brightness(0) saturate(100%) invert(12%) sepia(51%) saturate(5801%) hue-rotate(219deg) brightness(92%) contrast(94%)"};
`;

const NavIcon = styled.img`
  width: 18px;
  height: 18px;

  :active {
    filter: brightness(0) saturate(100%) invert(12%) sepia(51%) saturate(5801%)
      hue-rotate(219deg) brightness(92%) contrast(94%);
  }
`;

const LeftSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  margin-left: 15px;

  div {
    cursor: pointer;
    display: flex;
    align-items: center;
    scale: 1.2;
  }
`;

const RightSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  margin-right: 2px;

  div {
    cursor: pointer;
    display: flex;
    align-items: center;
    scale: 1.2;
  }
`;

const SearchInput = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(0%, -15%);
  width: 98%;
  padding: 8px;
  background-color: #dddddd;
  border-radius: 4px;
  border: 1px solid #ccc;
  display: block;
`;

const CancelButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-50%, 20%);
  width: 20px;
  height: 20px;
  border: none;
  background: none;
`;

const ContentWrap = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 173px);
  margin-top: 15px;
`;

const StyledModal = styled(AlertModal)`
  background-color: #eeeeee;
  color: white;
  width: 80vw;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 15px;
  padding-bottom: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledUpLoadModal = styled(AlertModal)`
  background-color: #eeeeee;
  color: white;
  width: 80vw;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 15px;
  padding-bottom: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ModalTitle = styled.div`
  text-align: center;
  background-color: #09348a;
  color: #fff;
  width: 100%;
  font-size: 1.1rem;
  font-weight: 700;
  padding: 20px 0;
  border-radius: 15px 15px 0 0;
  margin-bottom: 30px;
`;

const StyledInputContainer = styled.div`
  width: 80%;
  margin: 10px 0;
  position: relative;
  border-bottom: 1px solid #09348a;
  display: flex;
  align-items: center;
`;

const StyledInputLabel = styled.span`
  color: #000;
  font-size: 0.8rem;
  text-align: left;
  width: 25%;
`;

const StyledInput = styled.input`
  width: 75%;
  padding: 10px;
  background: none;
  border: none;
  color: #000000;

  &:focus {
    outline: none;
  }
`;

const StyledButton = styled.button`
  width: 100%;
  margin-top: 20px;
  background: none;
  color: #000;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

const FileInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #000;
  border-radius: 15px;
  background-color: #ffffff;
  width: 60%;
  padding: 10px;
  cursor: pointer;
  position: relative;
`;

const FileInput = styled.input`
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
`;

const RemoveContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

const UploadedFileName = styled.span`
  font-size: 1.2rem;
  color: #000;
  margin-right: 5px;
`;

const RemoveFileButton = styled.div`
  border: none;
  background: none;
  color: #ed1a2f;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  align-content: center;
`;

const PlusIcon = styled.span`
  font-size: 24px;
  color: #000;
`;

const ActiveIcon = styled.div<{ editMode?: boolean }>`
  color: ${({ editMode }) => (editMode ? "#000" : "none")};
`;

const ButtonWrap = styled.div`
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 50%);
`;

const MusicSetup: React.FC = ({ location }: any) => {
  const awsAccessKey = useSelector((state: RootState) => state.awsAccessKey);
  const awsSecretKey = useSelector((state: RootState) => state.awsSecretKey);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const pro_no = location?.state?.pro_no;
  const MLoginInfo = useSelector((state: RootState) => state.MLoginInfo);
  const [isVideoIuModalOpen, setIsVideoIuModalOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  // const buttonToShow = ["logo", "play", "profile", "setup"];
  const buttonToShow = ["logo", "stopWatch", "vibeOn"];

  const [activePage, setActivePage] = useState("music");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [confirmModalMessage, setConfirmModalMessage] = useState("");
  const [musicList, setMusicList] = useState<Interface.MusicItem[]>([]);
  const [folderList, setFolderList] = useState<Interface.FolderItem[]>([]);

  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [newFolderTitle, setNewFolderTitle] = useState("");
  const [newFolderSubtitle, setNewFolderSubtitle] = useState("");

  const [inFolder, setInFolder] = useState(false);
  const [currentMusicList, setCurrentMusicList] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [showSearch, setShowSearch] = useState(false);

  const [playFoldNo, setPlayFoldNo] = useState("");

  const [fileModalIsOpen, setFileModalIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedConfigNo, setSelectedConfigNo] = useState<string[]>([]);
  const [selectedMusic, setSelectedMusic] = useState<Set<string>>(new Set());
  const [selectedFolders, setSelectedFolders] = useState<Set<string>>(
    new Set()
  );
  const [editMode, setEditMode] = useState(false);

  const [random, setRandom] = useState(false);

  const [dnStatus, setDnStatus] = useState<
    "ready" | "loading" | "error" | "success"
  >("ready");

  const [folderSelections, setFolderSelections] = useState<{
    [key: string]: boolean;
  }>({});

  const s3Client = useMemo(() => {
    if (!awsAccessKey || !awsSecretKey) return null;

    AWS.config.update({
      accessKeyId: awsAccessKey,
      secretAccessKey: awsSecretKey,
    });

    return new AWS.S3({
      params: { Bucket: Config.S3_BUCKET },
      region: Config.S3_REGION,
    });
  }, [awsAccessKey, awsSecretKey]);

  const putObjectWrapper = async (params: any) => {
    try {
      if (!s3Client) {
        throw new Error("S3 client is not initialized");
      }
      const data = await s3Client.putObject(params).promise();
      console.log("업로드 성공");
      return data;
    } catch (err) {
      console.error("업로드 오류", err);
      throw err;
    }
  };

  const deleteObjectWrapper = async (params: any) => {
    try {
      if (!s3Client) {
        throw new Error("S3 client is not initialized");
      }
      const data = await s3Client.deleteObject(params).promise();
      console.log("삭제 성공");
      return data;
    } catch (err) {
      console.error("삭제 오류", err);
      throw err;
    }
  };

  function handleHeaderButtonClick(page: string) {
    setActivePage(page);
  }

  const fetchMusicList = async () => {
    setLoading(true);
    try {
      const data = {
        center_no: MLoginInfo.MLoginInfo.center_no,
        pro_no: pro_no,
        play_fold_no: inFolder ? playFoldNo : "",
      };
      console.log("어떤 계정데이터를 호출하나?", data);
      const response = await ServerApi.m_app_play_list(data);
      if (response && response.rsp_code === "100") {
        console.log("m_app_play_list API 응답:", response);

        if (response.music_gb === "1") {
          setRandom(false);
        } else if (response.music_gb === "2") {
          setRandom(true);
        }

        let filteredMusicList;
        if (inFolder) {
          filteredMusicList = response.array1.filter(
            (item: any) => item.play_fold_no === playFoldNo
          );
        } else {
          filteredMusicList = response.array1.filter(
            (item: any) => item.play_fold_no === ""
          );
        }

        setMusicList(filteredMusicList);
        if (!inFolder) {
          setFolderList(response.array2);
          const initialSelections = response.array2.reduce(
            (acc: any, folder: any) => {
              acc[folder.play_fold_no] = folder.fold_check === "y";
              return acc;
            },
            {}
          );
          setFolderSelections(initialSelections);
        }
      } else {
        console.error("API call unsuccessful:", response?.msg);
      }
    } catch (error) {
      console.error("Fetching data failed:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMusicList();
  }, [MLoginInfo.MLoginInfo, pro_no, inFolder, playFoldNo]);

  // 모달 닫기
  const closeAlertModal = () => {
    setModalIsOpen(false);
    setFileModalIsOpen(false);
    setSelectedFile(null);
  };

  // 폴더 생성
  const handleNewFolder = async () => {
    setDnStatus("loading");
    if (!newFolderTitle.trim()) {
      setTimeout(() => {
        setDnStatus("error");
        setTimeout(() => {
          setShowCreateFolderModal(false);
          setModalIsOpen(true);
          setModalMessage("제목을 입력해주세요.");
        }, 1000);
      }, 500);
      return;
    }

    try {
      const response = await ServerApi.m_app_play_fold_i({
        title: newFolderTitle,
        sub_title: newFolderSubtitle,
        center_no: MLoginInfo.MLoginInfo.center_no,
      });
      if (response && response.rsp_code === "100") {
        setTimeout(() => {
          setDnStatus("success");
          console.log("폴더생성 성공");
        }, 1000);
      } else {
        console.error("실패");
        setDnStatus("error");
      }
    } catch (error) {
      console.error("에러", error);
      setDnStatus("error");
    } finally {
      setTimeout(() => {
        setShowCreateFolderModal(false);
        setNewFolderTitle("");
        setNewFolderSubtitle("");
        setDnStatus("ready");
        fetchMusicList();
      }, 1500);
    }
  };

  // 음악 선택 변경 핸들러
  const handleMusicSelectionChange = useCallback(
    (config_no: string, isSelected: boolean) => {
      setSelectedMusic((prev) => {
        const newSelectedMusic = new Set(prev);
        if (isSelected) {
          newSelectedMusic.add(config_no);
        } else {
          newSelectedMusic.delete(config_no);
        }
        console.log("Selected Music Updated:", newSelectedMusic);
        return newSelectedMusic;
      });
    },
    []
  );

  // 폴더 선택
  const handleFolderSelectionChange = (
    play_fold_no: string,
    isSelected: boolean
  ) => {
    const newSelectedFolders = new Set(selectedFolders);
    if (isSelected) {
      newSelectedFolders.add(play_fold_no);
    } else {
      newSelectedFolders.delete(play_fold_no);
    }
    setSelectedFolders(newSelectedFolders);
  };

  // 공용 핸들러
  // 음악 추가 핸들러
  const handleMusicClick = () => {
    setFileModalIsOpen(true);
  };

  const upLoadMusic = async () => {
    setDnStatus("loading");
    if (!selectedFile) {
      setTimeout(() => {
        setDnStatus("error");
        setTimeout(() => {
          setFileModalIsOpen(false);
          setModalMessage("음악 파일을 선택해주세요");
          setModalIsOpen(true);
          setDnStatus("ready");
        }, 1000);
      }, 500);
      return;
    }

    const fileKey = selectedFile.name;
    const params = {
      Bucket: Config.S3_BUCKET,
      Key: fileKey,
      Body: selectedFile,
    };

    try {
      const data = await putObjectWrapper(params);
      console.log("업로드 성공", data.$response);
      setDnStatus("success");
      const uploadedFileURL = `https://${Config.S3_BUCKET}.s3.${Config.S3_REGION}.amazonaws.com/${fileKey}`;

      try {
        const responseData = {
          array: [
            {
              config_type: "c1",
              file_nm: uploadedFileURL,
              dis_nm: fileKey,
            },
          ],
          play_fold_no: inFolder ? playFoldNo : "",
          center_no: MLoginInfo.MLoginInfo.center_no,
        };
        console.log("업로드 데이터들", responseData);
        const response = await ServerApi.m_app_play_i(responseData);
        if (response && response.rsp_code === "100") {
          console.log("음악이 성공적으로 등록됨", response);
          setTimeout(() => {
            setFileModalIsOpen(false);
            setModalMessage("음악 파일이 성공적으로 등록되었습니다.");
            setModalIsOpen(true);
            fetchMusicList();
          }, 1000);
        } else {
          console.error("음악 등록 에러 1", response?.msg);
          setTimeout(() => {
            setDnStatus("error");
            setTimeout(() => {
              setModalMessage("음악 파일등록 실패");
              setModalIsOpen(true);
            }, 1000);
          }, 1000);
        }
      } catch (error) {
        console.error("음악 등록 에러 2", error);
        setTimeout(() => {
          setDnStatus("error");
          setTimeout(() => {
            setModalMessage("음악 파일등록 실패");
            setModalIsOpen(true);
          }, 1000);
        }, 1000);
      }
    } catch (error) {
      console.error("업로드 실패", error);
    }
  };

  // 검색 버튼 핸들러
  const handleSearchClick = () => {
    setShowSearch(!showSearch);
  };

  const handleAllClick = () => {
    // 현재 모든 폴더가 선택되어 있는지 확인
    const allFoldersSelected = folderList.length === selectedFolders.size;
    // 현재 모든 음악이 선택되어 있는지 확인
    const allMusicSelected = musicList.length === selectedMusic.size;

    // 모든 폴더와 음악이 선택되어 있다면 모두 해제
    if (allFoldersSelected && allMusicSelected) {
      setSelectedFolders(new Set());
      setSelectedMusic(new Set());
    } else {
      // 모든 폴더 선택
      const allFolderIds = new Set(
        folderList
          .map((folder) => folder.play_fold_no)
          .filter((id): id is string => !!id)
      );
      setSelectedFolders(allFolderIds);

      // 모든 음악 선택
      const allMusicConfigNos = new Set(
        musicList
          .map((music) => music.config_no)
          .filter((id): id is string => !!id)
      );
      setSelectedMusic(allMusicConfigNos);
    }
  };

  // 수정모드 진입 핸들러
  const handleEditClick = () => {
    setEditMode(!editMode);
  };

  // 음악 삭제 핸들러
  const handleTrashClick = async () => {
    if (selectedMusic.size === 0 && selectedFolders.size === 0) {
      setModalMessage("삭제할 음악 또는 폴더를 선택해주세요.");
      setModalIsOpen(true);
      return;
    }

    setConfirmModalIsOpen(true);
    setConfirmModalMessage("선택한 음악 또는 폴더를 삭제하시겠습니까?");
  };

  const handleConfirmDelete = async () => {
    setConfirmModalIsOpen(false);

    // 음악 파일 삭제 로직
    const selectedMusicArray = Array.from(selectedMusic);
    const deleteServerPromises = selectedMusicArray.map(async (config_no) => {
      try {
        await ServerApi.m_app_play_d({ array: [{ config_no }] });
        console.log(`${config_no} 서버에서 음악 정보 삭제 성공`);
      } catch (error) {
        console.error(`${config_no} 서버에서 음악 정보 삭제 실패`, error);
      }
    });

    await Promise.all(deleteServerPromises);

    // AWS S3에서 음악 파일 삭제
    const deleteS3Promises = selectedMusicArray.map(async (config_no) => {
      const item = musicList.find((music) => music.config_no === config_no);
      if (item) {
        const fileKey = `bg/${item.dis_nm}`;
        const params = {
          Bucket: Config.S3_BUCKET,
          Key: fileKey,
        };

        try {
          await deleteObjectWrapper(params);
          console.log(`${fileKey} S3에서 삭제 성공`);
        } catch (err) {
          console.error(`${fileKey} S3에서 삭제 오류`, err);
        }
      }
    });

    await Promise.all(deleteS3Promises);

    // 폴더 삭제 로직
    const deleteFolderPromises = Array.from(selectedFolders).map(
      async (play_fold_no) => {
        try {
          const response = await ServerApi.m_app_play_fold_d({
            play_fold_no: play_fold_no,
            center_no: MLoginInfo.MLoginInfo.center_no,
          });

          if (response && response.rsp_code === "100") {
            console.log(`폴더 삭제 성공: ${play_fold_no}`);
            fetchMusicList();
          } else {
            console.error(`폴더 삭제 실패: ${play_fold_no}`, response?.msg);
            throw new Error(`폴더 삭제 실패: ${play_fold_no}`);
          }
        } catch (err) {
          console.error(`폴더 삭제 중 오류 발생: ${play_fold_no}`, err);
          throw err;
        }
      }
    );

    try {
      // 음악 파일과 폴더 모두 삭제 처리
      await Promise.all([...deleteS3Promises, ...deleteFolderPromises]);
      setModalMessage("모든 선택된 파일 및 폴더가 삭제되었습니다.");
      setModalIsOpen(true);
      // 서버에서 음악 파일 정보 삭제
      if (selectedConfigNo.length > 0) {
        const response = await ServerApi.m_app_play_d({
          array: selectedConfigNo.map((config_no) => ({ config_no })),
        });

        if (response && response.rsp_code === "100") {
          console.log("서버에서 음악 삭제 성공", response);
        } else {
          setModalMessage("음악 삭제에 실패했습니다.");
          setModalIsOpen(true);
        }
      }

      // 음악 목록 및 선택된 항목 목록 초기화
      fetchMusicList();
      setSelectedMusic(new Set());
      setSelectedFolders(new Set<string>());
    } catch (error) {
      setModalMessage("음악 삭제에 실패했습니다.");
      setModalIsOpen(true);
    }
  };

  // 검색 로직
  // 한글과 영문의 조합을 위한 정규화 추가
  const normalizedSearchQuery = searchQuery
    .normalize("NFC")
    .toLowerCase()
    .trim();
  const filteredMusicList = musicList.filter((music) =>
    music.dis_nm?.normalize("NFC").toLowerCase().includes(normalizedSearchQuery)
  );
  const filteredFolderList = folderList.filter((folder) =>
    folder.title?.normalize("NFC").toLowerCase().includes(normalizedSearchQuery)
  );

  // 폴더 진입 핸들러
  const handleFolderClick = async (folder: any) => {
    setPlayFoldNo(folder.play_fold_no);
    setInFolder(true);

    // 폴더 내부 음악들의 등록 상태 확인
    try {
      const registeredList = await ServerApi.m_app_pro_my_play_list({ pro_no });
      const folderMusicList = await ServerApi.m_app_play_list({
        center_no: MLoginInfo.MLoginInfo.center_no,
        pro_no,
        play_fold_no: folder.play_fold_no,
      });

      if (registeredList?.rsp_code === "100" && folderMusicList?.array1) {
        // 폴더 내 각 음악의 등록 상태 확인
        const newSelectedMusic = new Set<string>();
        folderMusicList.array1.forEach((music: any) => {
          const isRegistered = registeredList.array.some(
            (regMusic: any) => regMusic.config_no === music.config_no
          );
          if (isRegistered) {
            newSelectedMusic.add(music.config_no);
          }
        });
        setSelectedMusic(newSelectedMusic);
      }
    } catch (error) {
      console.error("폴더 내 음악 상태 확인 중 오류:", error);
    }
  };

  // 폴더 진입하면 리스트 새로 고침
  useEffect(() => {
    if (inFolder) {
      fetchMusicList();
    }
  }, [playFoldNo]);

  // 폴더 내부 핸들러
  const handleBackClick = () => {
    setInFolder(false);
    setCurrentMusicList([]);
    setSearchQuery("");
    setShowSearch(false);
    setSelectedMusic(new Set()); // 선택된 음악 초기화
  };

  // 파일 삭제 핸들러
  const handleRemoveFile = () => {
    setSelectedFile(null);
  };

  // 파일 선택 핸들러
  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault(); // 이벤트 전파 중단

      if (e.target.files && e.target.files[0]) {
        setSelectedFile(e.target.files[0]);
        // 파일 선택 후 input 값 초기화
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    },
    []
  );

  const handleFileInputClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // 이벤트 버블링 중단
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleInfinity = async () => {
    try {
      const data = {
        pro_no: pro_no,
        music_gb: "2",
      };
      const response = await ServerApi.m_app_pro_play_gb_i(data);
      if (response && response.rsp_code === "100") {
        console.log("m_app_pro_play_gb_i API 응답:", response);
        console.log("랜덤재생");
        fetchMusicList();
      } else {
        console.error("API call unsuccessful:", response?.msg);
      }
    } catch (error) {
      console.error("Fetching data failed:", error);
    }
  };

  const handleRotate = async () => {
    try {
      const data = {
        pro_no: pro_no,
        music_gb: "1",
      };
      const response = await ServerApi.m_app_pro_play_gb_i(data);
      if (response && response.rsp_code === "100") {
        console.log("m_app_pro_play_gb_i API 응답:", response);
        console.log("순차재생");
        fetchMusicList();
      } else {
        console.error("API call unsuccessful:", response?.msg);
      }
    } catch (error) {
      console.error("Fetching data failed:", error);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingProgress />
      ) : (
        <>
          <Overlay isModalOpen={isVideoIuModalOpen} />
          <ProgramsVideoContainer isModalOpen={isVideoIuModalOpen}>
            <MobileHeader
              textOne={"MUSIC"}
              // textTwo={"ACCOUNT SETUP"}
              onTextOneClick={() => handleHeaderButtonClick("music")}
              onTextTwoClick={() => handleHeaderButtonClick("account")}
              initialActive={"textOne"}
            />

            {activePage === "music" && (
              <>
                <MusicNav>
                  {inFolder ? (
                    <>
                      <LeftSection>
                        <div onClick={handleBackClick}>
                          <FontAwesomeIcon icon={faArrowLeft} />
                        </div>
                        <NavIconWrapper
                          onClick={handleInfinity}
                          filter={
                            !random
                              ? "none"
                              : "brightness(0) saturate(100%) invert(12%) sepia(51%) saturate(5801%) hue-rotate(219deg) brightness(92%) contrast(94%)"
                          }
                        >
                          <NavIcon src={repeat} alt="repeat" />
                        </NavIconWrapper>
                        <NavIconWrapper
                          onClick={handleRotate}
                          filter={
                            random
                              ? "none"
                              : "brightness(0) saturate(100%) invert(12%) sepia(51%) saturate(5801%) hue-rotate(219deg) brightness(92%) contrast(94%)"
                          }
                        >
                          <NavIcon src={rotate} alt="rotate" />
                        </NavIconWrapper>
                      </LeftSection>
                      <RightSection>
                        {editMode && <div onClick={handleAllClick}>All</div>}
                        <div onClick={handleEditClick}>
                          <NavIcon src={check} alt="check" />
                        </div>
                        <div onClick={handleMusicClick}>
                          <NavIcon src={upload} alt="upload" />
                        </div>
                        <div onClick={handleTrashClick}>
                          <NavIcon src={trash} alt="trash" />
                        </div>
                      </RightSection>
                    </>
                  ) : (
                    <>
                      <LeftSection>
                        <div onClick={() => setShowCreateFolderModal(true)}>
                          <FaRegFolder />
                        </div>
                        <div onClick={handleMusicClick}>
                          <NavIcon src={upload} alt="vibe" />
                        </div>
                        <div onClick={handleSearchClick}>
                          <NavIcon src={zoom} alt="zoom" />
                        </div>
                        {editMode && (
                          <>
                            <NavIconWrapper
                              onClick={handleInfinity}
                              filter={!random ? "none" : ""}
                            >
                              {random ? (
                                <NavIcon src={repeat} alt="repeat" />
                              ) : (
                                <NavIcon src={repeat} alt="repeat" />
                              )}
                            </NavIconWrapper>
                            <NavIconWrapper
                              onClick={handleRotate}
                              filter={random ? "none" : ""}
                            >
                              {!random ? (
                                <NavIcon src={rotate} alt="rotate" />
                              ) : (
                                <NavIcon src={rotate} alt="rotate" />
                              )}
                            </NavIconWrapper>
                          </>
                        )}
                      </LeftSection>
                      <RightSection>
                        {editMode && <div onClick={handleAllClick}>All</div>}
                        <ActiveIcon
                          onClick={handleEditClick}
                          editMode={editMode}
                        >
                          <FontAwesomeIcon icon={faCheck} />
                        </ActiveIcon>
                        <div onClick={handleTrashClick}>
                          <NavIcon src={trash} alt="trash" />
                        </div>
                      </RightSection>
                    </>
                  )}
                  {showSearch && (
                    <>
                      <SearchInput
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                      <CancelButton>
                        <IoCloseCircle
                          style={{ color: "#09348a", scale: "1.5" }}
                          onClick={() => {
                            setShowSearch(false);
                          }}
                        />
                      </CancelButton>
                    </>
                  )}
                </MusicNav>
                <ContentWrap>
                  {inFolder ? (
                    <MusicContents
                      musicList={filteredMusicList}
                      setMusicList={setMusicList}
                      selectedMusic={selectedMusic}
                      onMusicSelectionChange={handleMusicSelectionChange}
                      editMode={editMode}
                      fetchMusicList={fetchMusicList}
                      handleAllClick={handleAllClick}
                      pro_no={pro_no}
                    />
                  ) : (
                    <>
                      <MusicFolder
                        editMode={editMode}
                        folderList={filteredFolderList}
                        selectedFolders={selectedFolders}
                        onFolderSelectionChange={handleFolderSelectionChange}
                        onClick={handleFolderClick}
                        pro_no={pro_no}
                        folderSelections={folderSelections}
                        setFolderSelections={setFolderSelections}
                        fetchMusicList={fetchMusicList}
                      />
                      <MusicContents
                        musicList={filteredMusicList}
                        setMusicList={setMusicList}
                        selectedMusic={selectedMusic}
                        onMusicSelectionChange={handleMusicSelectionChange}
                        editMode={editMode}
                        fetchMusicList={fetchMusicList}
                        handleAllClick={handleAllClick}
                        pro_no={pro_no}
                      />
                    </>
                  )}
                </ContentWrap>
                <div className="buttonContainer">
                  <AccountButton
                    buttonUse={buttonToShow}
                    mod_yn={MLoginInfo.MLoginInfo.mod_yn}
                    pro_no={pro_no}
                  />
                </div>
              </>
            )}

            {activePage === "account" && <></>}
          </ProgramsVideoContainer>

          <AlertModal
            isOpen={confirmModalIsOpen}
            onRequestClose={closeAlertModal}
            contentLabel="confirmModal"
            className="modalAlertBox"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                zIndex: "99",
              },
            }}
          >
            <h2>{confirmModalMessage}</h2>
            <div style={{ marginTop: "20px" }}>
              <button
                onClick={handleConfirmDelete}
                style={{ marginRight: "10px" }}
              >
                OK
              </button>
              <button onClick={() => setConfirmModalIsOpen(false)}>
                cancel
              </button>
            </div>
          </AlertModal>
          <AlertModal
            isOpen={modalIsOpen}
            onRequestClose={closeAlertModal}
            contentLabel="Alert Modal"
            className="modalAlertBox"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                zIndex: "99",
              },
            }}
          >
            <h2>{modalMessage}</h2>
            <button onClick={closeAlertModal}>OK</button>
          </AlertModal>
          <StyledUpLoadModal
            isOpen={fileModalIsOpen}
            onRequestClose={closeAlertModal}
            contentLabel="file upload"
            className="modalAlertBox"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                zIndex: "99",
              },
            }}
          >
            <ModalTitle>MUSIC</ModalTitle>
            <IoCloseCircle
              style={{
                color: "#fff",
                position: "absolute",
                right: "10px",
                top: "20px",
                fontSize: "1.2rem",
              }}
              onClick={closeAlertModal}
            />
            {!selectedFile && (
              <FileInputContainer onClick={handleFileInputClick}>
                <FileInput
                  type="file"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                />
                <PlusIcon>+</PlusIcon>
              </FileInputContainer>
            )}
            {selectedFile && (
              <RemoveContainer>
                <UploadedFileName>{selectedFile.name}</UploadedFileName>
                <RemoveFileButton onClick={handleRemoveFile}>
                  <IoCloseCircle />
                </RemoveFileButton>
              </RemoveContainer>
            )}
            <ButtonWrap>
              <NewSaveButton
                text={"SAVE"}
                dnStatus={dnStatus}
                setDnStatus={setDnStatus}
                myClick={upLoadMusic}
              />
            </ButtonWrap>
          </StyledUpLoadModal>

          {showCreateFolderModal && (
            <StyledModal
              isOpen={showCreateFolderModal}
              onRequestClose={() => setShowCreateFolderModal(false)}
              contentLabel="Create New Folder"
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  zIndex: "99",
                },
              }}
            >
              <ModalTitle>PLAYLIST</ModalTitle>
              <StyledInputContainer>
                <StyledInputLabel>Title</StyledInputLabel>
                <StyledInput
                  type="text"
                  value={newFolderTitle}
                  onChange={(e) => setNewFolderTitle(e.target.value)}
                  placeholder="Please Enter"
                />
              </StyledInputContainer>
              <StyledInputContainer>
                <StyledInputLabel>Subtitle</StyledInputLabel>
                <StyledInput
                  type="text"
                  value={newFolderSubtitle}
                  onChange={(e) => setNewFolderSubtitle(e.target.value)}
                  placeholder="Please Enter"
                />
              </StyledInputContainer>
              <StyledButton>
                <NewSaveButton
                  text={"SAVE"}
                  dnStatus={dnStatus}
                  setDnStatus={setDnStatus}
                  myClick={handleNewFolder}
                />
              </StyledButton>
            </StyledModal>
          )}
        </>
      )}
    </>
  );
};

export default MusicSetup;
